<template>
  <span
    v-if="data.item.delegator"
    style="font-size: smaller;"
  >
    {{ data.item.delegator.name }}
  </span>
</template>

<script>
export default {
  name: 'DelegatorCell',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
}
</script>
