<template>
  <span>
    <slot
      name="show"
      v-bind="data"
    >
      <div class="text-center">
        <span v-if="data.item.email_sended === 1">
          <feather-icon
            icon="CheckIcon"
            size="25"
            color="#02111b"
          />
        </span>

        <span v-else>
          <feather-icon
            icon="XIcon"
            size="25"
            color="red"
          />
        </span>
      </div>
    </slot>
  </span>
</template>

<script>
export default {
  name: 'EmailSendedCell',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
}
</script>
