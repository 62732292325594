<template>
  <div>        <div
                 v-if="data.item.first_reviewer === null"
                 v-b-popover.hover.topright="'+ add first reviewer'"
                 style="width: 100% !important; height: 24px !important"
                 class="hover-pointer-empty text-center"
                 @click="
                   $router.push({
                     name: 'assign-engineer',
                     params: { id: data.item.id, type: 'first_reviewer' },
                     query: {
                       certificate_ar: data.item.certificate.ar,
                       certificate_ar: data.item.certificate.ar,
                       sub_certificate_ar: data.item.sub_certificate.ar,
                       sub_certificate_en: data.item.sub_certificate.en,
                       request_code: data.item.request_code
                     }
                   })
                 "
               />

    <div
      v-b-popover.hover.topright="'+ add first reviewer'"
      style="height: 100% !important; display: flex; align-items: center; justify-content: center;"
      class="hover-pointer text-center"
      @click="
        $router.push({
          name: 'assign-engineer',
          params: { id: data.item.id, type: 'first_reviewer' },
          query: {
            certificate_ar: data.item.certificate.ar,
            certificate_ar: data.item.certificate.ar,
            sub_certificate_ar: data.item.sub_certificate.ar,
            sub_certificate_en: data.item.sub_certificate.en,
            request_code: data.item.request_code
          }
        })
      "
    >
      <span style="font-size: smaller;">
        {{ data.item.first_reviewer }}
      </span>
    </div>
  </div>

</template>

<script>
export default {
  name: 'FirstReviewerCell',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
}
</script>
