<template>
  <span>
    <a
      class="request-code-a"
      :href="
        `https://engineers.gulftic.com/orders/order/${data.item.id}?role=${accessRole}&name=${accessName}&email=${accessEmail}&token=${accessToken}`
      "
      target="_blank"
    >
      <div v-b-popover.hover.topright="'Go To Request'">
        <span style="font-size: smaller; text-wrap: nowrap">
          {{ data.item.request_code }}
        </span>
      </div>
    </a>

  </span>
</template>

<script>
export default {
  name: 'RequestCodeCell',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      accessRole: localStorage.getItem('accessRole') || '',
      accessToken: localStorage.getItem('accessToken') || '',
      accessName: localStorage.getItem('name') || '',
      accessEmail: localStorage.getItem('email') || '',
    }
  },
}
</script>
