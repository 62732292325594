var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.data.item.parent_request_code === null)?_c('div',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.topright",value:('+ assign parent for request'),expression:"'+ assign parent for request'",modifiers:{"hover":true,"topright":true}}],staticClass:"hover-pointer-empty text-center",staticStyle:{"width":"100% !important","height":"24px !important"},on:{"click":function($event){return _vm.$router.push({
        name: 'parent_request_code',
        params: { id: _vm.data.item.id}
      })}}}):_vm._e(),_c('div',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.topright",value:('+ assign parent for request'),expression:"'+ assign parent for request'",modifiers:{"hover":true,"topright":true}}],staticClass:"hover-pointer text-center",staticStyle:{"height":"100% !important","display":"flex","align-items":"center","justify-content":"center"},on:{"click":function($event){return _vm.$router.push({
        name: 'parent_request_code',
        params: { id: _vm.data.item.id },
        query: { parent_code : _vm.data.item.parent_request_code}
      })}}},[_c('span',{staticStyle:{"font-size":"smaller"}},[_vm._v(" "+_vm._s(_vm.data.item.parent_request_code)+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }