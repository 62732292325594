var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.data.item.first_reviewer === null)?_c('div',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.topright",value:('+ add first reviewer'),expression:"'+ add first reviewer'",modifiers:{"hover":true,"topright":true}}],staticClass:"hover-pointer-empty text-center",staticStyle:{"width":"100% !important","height":"24px !important"},on:{"click":function($event){return _vm.$router.push({
                   name: 'assign-engineer',
                   params: { id: _vm.data.item.id, type: 'first_reviewer' },
                   query: {
                     certificate_ar: _vm.data.item.certificate.ar,
                     certificate_ar: _vm.data.item.certificate.ar,
                     sub_certificate_ar: _vm.data.item.sub_certificate.ar,
                     sub_certificate_en: _vm.data.item.sub_certificate.en,
                     request_code: _vm.data.item.request_code
                   }
                 })}}}):_vm._e(),_c('div',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.topright",value:('+ add first reviewer'),expression:"'+ add first reviewer'",modifiers:{"hover":true,"topright":true}}],staticClass:"hover-pointer text-center",staticStyle:{"height":"100% !important","display":"flex","align-items":"center","justify-content":"center"},on:{"click":function($event){return _vm.$router.push({
        name: 'assign-engineer',
        params: { id: _vm.data.item.id, type: 'first_reviewer' },
        query: {
          certificate_ar: _vm.data.item.certificate.ar,
          certificate_ar: _vm.data.item.certificate.ar,
          sub_certificate_ar: _vm.data.item.sub_certificate.ar,
          sub_certificate_en: _vm.data.item.sub_certificate.en,
          request_code: _vm.data.item.request_code
        }
      })}}},[_c('span',{staticStyle:{"font-size":"smaller"}},[_vm._v(" "+_vm._s(_vm.data.item.first_reviewer)+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }