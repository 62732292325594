<template>
  <span>
    <slot
      name="show"
      v-bind="data"
    >
      <div class="text-center">
        <span v-if="data.item.invoice_id">
          <feather-icon
            v-b-popover.hover.topright="'Download Invoice'"
            icon="FileTextIcon"
            class="downlaod-icon"
            size="25"
            style="cursor: pointer;"
            @click="handleInvoice(data.item.invoice_id , data.item.office_id , data.item.request_code)"
          />
        </span>
        <span v-else />
      </div>
    </slot>

  </span>
</template>

<script>
import createInvoice from '../control-requests/createInvoice'

export default {
  name: 'InvoiceCell',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    handleInvoice(invoiceId, officeId, requestCode) {
      createInvoice(invoiceId, officeId, requestCode)
    },
  },
}
</script>
