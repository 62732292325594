<template>
  <span>
    <div
      v-if="data.item.office === null"
      v-b-popover.hover.topright="'+ add office'"
      style="width: 100% !important; height: 24px !important;font-size: smaller;"
      class="hover-pointer-empty text-center"
      @click="
        $router.push({
          name: 'set-office',
          params: { id: data.item.id }
        })
      "
    />
    <div
      v-b-popover.hover.topright="'+ add office'"
      class="hover-pointer text-center"
      style="height: 100% !important; display: flex; align-items: center; justify-content: center;font-size: smaller;"
      @click="
        $router.push({
          name: 'set-office',
          params: { id: data.item.id }
        })
      "
    >
      <span v-if="data.item.office">
        {{ data.item.office }}
      </span>
    </div>

  </span>
</template>

<script>
export default {
  name: 'OfficeCell',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
}
</script>
