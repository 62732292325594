<template>
  <div>
    <div
      v-if="data.item.parent_request_code === null"
      v-b-popover.hover.topright="'+ assign parent for request'"
      style="width: 100% !important; height: 24px !important"
      class="hover-pointer-empty text-center"
      @click="
        $router.push({
          name: 'parent_request_code',
          params: { id: data.item.id}
        })
      "
    />
    <div
      v-b-popover.hover.topright="'+ assign parent for request'"
      style="height: 100% !important; display: flex; align-items: center; justify-content: center;"
      class="hover-pointer text-center"
      @click="
        $router.push({
          name: 'parent_request_code',
          params: { id: data.item.id },
          query: { parent_code : data.item.parent_request_code}
        })
      "
    >
      <span style="font-size: smaller;">
        {{ data.item.parent_request_code }}
      </span>
    </div>
  </div>

</template>

<script>
export default {
  name: 'SetParentCell',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
}
</script>
