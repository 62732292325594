<template>
  <div>
    <div v-if="role === 'super-accountant' || role === 'accountant'">
      <span
        style="font-size: smaller;"
      >
        {{ data.item.sub_certificate.ar }}
      </span>
      <span
        v-if="data.item.sub_certificate.ar && data.item.sub_certificate.en"
        style="font-size: smaller;"
      >
        -
      </span>
      <span
        style="font-size: smaller;"
      >
        {{ data.item.sub_certificate.en }}
      </span>
    </div>
    <div v-else>
      <div
        v-if="data.item.sub_certificate === null"
        v-b-popover.hover.topright="'edit sub certificate'"
        style="width: 100% !important; height: 24px !important"
        class="hover-pointer-empty text-center"
        @click="
          $router.push({
            name: 'edit-sub-certificate',
            params: { id: data.item.id }
          })
        "
      />
      <div
        v-b-popover.hover.topright="'edit sub certificate'"
        style="height: 100% !important; display: flex; align-items: center; justify-content: center;"
        class="hover-pointer text-center"
        @click="
          $router.push({
            name: 'edit-sub-certificate',
            params: { id: data.item.id }
          })
        "
      >
        <span
          style="font-size: smaller;"
        >
          {{ data.item.sub_certificate.ar }}
        </span>
        <span
          v-if="data.item.sub_certificate.ar && data.item.sub_certificate.en"
          style="font-size: smaller;"
        >
          -
        </span>
        <span
          style="font-size: smaller;"
        >
          {{ data.item.sub_certificate.en }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SubCertificateCell',
  props: {
    data: {
      type: Object,
      required: true,
    },
    role: {
      type: String,
      required: true,
    },
  },
}
</script>
