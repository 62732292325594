<template>
  <span>
    <div
      v-if="data.item.facility_name === ''"
      v-b-popover.hover.topright="'show facility info'"
      style="width: 100% !important; height: 24px !important"
      class="hover-pointer-empty text-center"
      @click="
        $router.push({
          name: 'request-histories',
          params: { id: data.item.id }
        })
      "
    />
    <div
      v-b-popover.hover.topright="'show facility info'"
      class="hover-pointer text-center "
      style="height: 100% !important; display: flex; align-items: center; justify-content: center;"
      @click="
        $router.push({
          name: 'request-histories',
          params: { id: data.item.id }
        })
      "
    >

      <span
        style="font-size: smaller;"
        class="text-nowrap"
      >
        {{ data.item.facility_name.en }}
      </span>
      <span v-if="data.item.facility_name.en && data.item.facility_name.ar">
        -
      </span>
      <span
        style="font-size: smaller;"
        class="text-nowrap"
      >
        {{ data.item.facility_name.ar }}
      </span>
    </div>

  </span>
</template>

<script>
export default {
  name: 'FacilityNameCell',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
}
</script>
