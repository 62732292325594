<template>
  <span>

    <span
      style="font-size: smaller;"
      class="text-nowrap px-1"
    >
      {{ data.item.last_status_update }}
    </span>

  </span>
</template>

<script>
export default {
  name: 'LastStatusUpdateCell',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
}
</script>
