<template>
  <span>

    <div v-if="role === 'process-manager'">
      <span
        style="font-size: smaller;"
        class="text-nowrap"
      >
        {{ data.item.payment_status }}
      </span>
    </div>
    <div v-else>
      <div
        v-b-popover.hover.topright="'+ add payment'"
        class="hover-pointer text-center "
        style="height: 100% !important; display: flex; align-items: center; justify-content: center;"
        @click="
          $router.push({
            name: 'edit-payment-status',
            params: { id: data.item.id }
          })
        "
      >
        <span
          style="font-size: smaller;"
          class="text-nowrap"
        >
          {{ data.item.payment_status }}
        </span>
      </div>
    </div>

  </span>
</template>

<script>
export default {
  name: 'PaymentStatusCell',
  props: {
    data: {
      type: Object,
      required: true,
    },
    role: {
      type: String,
      required: true,
    },
  },
}
</script>
