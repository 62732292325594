<template>
  <span>
    <div>
      <span
        style="font-size: smaller;"
      >
        {{ data.item.certificate.ar }}
      </span>
      <span v-if="data.item.certificate.ar && data.item.certificate.en">
        -
      </span>
      <span
        style="font-size: smaller;"
      >
        {{ data.item.certificate.en }}
      </span>
    </div>
  </span>
</template>

<script>
export default {
  name: 'CertificateCell',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
}
</script>
