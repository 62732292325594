<template>
  <span>
    <slot
      name="show"
      v-bind="data"
    >
      <div class="text-center">
        <span v-if="data.item.alert === 1">
          <feather-icon
            icon="ToggleRightIcon"
            class="cursor-pointer"
            size="25"
            color="#02111b"
            @click="changeAlertStatus(data.item.id , data.item.alert)"
          />
        </span>

        <span
          v-else
        ><feather-icon
          icon="ToggleLeftIcon"
          class="cursor-pointer"
          size="25"
          color="red"
          @click="changeAlertStatus(data.item.id , data.item.alert)"
        /></span>
      </div>
    </slot>
  </span>
</template>

<script>
import axios from 'axios'

export default {
  name: 'AlertCell',
  props: {
    data: {
      type: Object,
      required: true,
    },
    getAllDataPass: {
      type: Function,
      required: true,
    },
  },
  methods: {
    changeAlertStatus(id, alertStatus) {
      this.$swal({
        title: 'Are you sure?',
        text: 'You will be able to change status!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, change status!',
      }).then(result => {
        if (result.isConfirmed) {
          const newStatus = alertStatus === 0 ? 1 : 0
          axios
            .put(`requests/${id}/alert`, {
              alert: newStatus,
            })
            .then(() => {
              this.$swal('Changed!', 'Changed Successfully.', 'success')
              this.getAllDataPass(1)
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },

  },

}
</script>
